<template>
  <div class="tableList">
    <div class="search">
      <div class="button_group">
        <a-button
          type="primary"
          @click="addOrUpdate(null)"
        >添加</a-button>
      </div>
    </div>
    <div>
      <a-table
        rowKey="id"
        :columns="tableInfo.columns"
        :pagination="false"
        :dataSource="tableInfo.datas"
        :loading="loading"
        bordered
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        size="small"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: tableInfo.tableHeight }"
      >
        <template slot="num" slot-scope="text, record, index">
          {{ parseInt(index) + 1 }}
        </template>
       
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="action" slot-scope="info">
          <a-button
            @click="addOrUpdate(info)"
            size="small"
            type="primary"
          >编辑</a-button>
          <a-popconfirm
            title="是否确认禁用该角色"
            @confirm="changeStatus(info.id, 0)"
            v-if="info.status == 1"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <a-button
              size="small"
              type="danger"
            >禁用</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="是否确认启用该角色"
            @confirm="changeStatus(info.id, 1)"
            v-else
          >
            <a-icon slot="icon" type="question-circle-o" />
            <a-button  size="small" >启用</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <a-modal
      :title="editor.title"
      v-model="editor.visible"
      width="900px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <roleInfo :info="editor.info" @callback="modelCallBack" />
    </a-modal>
  </div>
</template>

<script>
const columns = [
 
  {
    title: "角色名称",
    dataIndex: "name",
    align: "center",
  },
{
    title: "角色说明",
    dataIndex: "description",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

import roleInfo from "./SystemRoleInfo";
import roleApi from "@/api/system/role";
let _this;
export default {
  name: "SystemRoleList",
  data() {
    return {
      loading: false,
      tableInfo: {
        datas: [],
        columns,
        tableHeight: window.innerHeight - 310,
      },
      selectRows: [],
      editor: {
        visible: false,
        info: null,
      },
    };
  },
  components: {
    roleInfo,
  },
  mounted() {
    _this = this;
    window.onresize = () => {
      _this.tableInfo.tableHeight = window.innerHeight - 310;
    };
    _this.getPageList();
  },
  methods: {
    getPageList() {
      _this.loading = true;
      roleApi.getRolesAll().then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data;
        }
        _this.loading = false;
      });
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    addOrUpdate(info) {
      _this.editor.title = "角色信息";
      _this.editor.info=info;
      _this.editor.visible = true;
    },
    changeStatusBatch(status) {
      let msg = status == 1 ? "启用" : "禁用";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的juese吗？`,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          _this.changeStatus(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    changeStatus(id, status) {
      let param = {
        ids: id,
        status: status,
      };
      _this.loading = true;
      roleApi
        .updateStatus(param)
        .then((res) => {
          if (res.errorCode === _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getPageList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.loading = false;
          }
        })
        .catch(() => {
          _this.loading = false;
        });
    },
    modelCallBack() {
      _this.editor.visible = false;
      _this.getPageList();
    },
  },
};
</script>

<style lang="less">
</style>