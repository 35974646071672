<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model
      ref="roleForm"
      :model="roleInfo"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="角色名称" prop="name">
        <a-input placeholder="请输入" v-model.trim="roleInfo.name" />
      </a-form-model-item>
      <a-form-model-item label="角色说明">
        <a-input
          type="textarea"
          placeholder="请输入角色说明"
          v-model.trim="roleInfo.description"
        />
      </a-form-model-item>
      <a-form-model-item label="应用权限">
          <a-checkbox-group v-model="checkedList" :options="apps" @change="appChanged"/>
      </a-form-model-item>
      <a-form-model-item v-for="item in tabs" :label="item.label" :key="item.value">
         <a-tabs default-active-key="menu">
          <a-tab-pane key="menu" tab="菜单权限">
            <a-tree
              v-model="item.checkedMenus"
              checkable
              :defaultExpandAll="true"
              :tree-data="item.menus"
            />
          </a-tab-pane>
          <a-tab-pane key="action" tab="功能权限">
            <a-checkbox-group v-model="item.checkedAuthorize">
                <a-row v-for="(v,k,i) in item.authorizes" :key="i" style="border-bottom:solid 1px #cccccc;margin-bottom:10px;padding-bottom:10px;">
                  <a-col :span="5">
                    {{ k }}
                  </a-col>
                  <a-col :span="19">
                    <a-checkbox v-for="c in v" :key="c.value" :value="c.value"  style="float:left;margin-left:0px;margin-right:10px">
                      {{ c.label }}
                    </a-checkbox>
                  </a-col>
                </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="data" tab="数据权限">
            <a-row v-for="d in item.dataPurview" :key="d.key" style="padding-bottom:10px">
              <a-col :span="5">{{d.key}}</a-col>
              <a-col :span="19">
                <a-checkbox-group v-model="d.dataPurview" :options="dataPurviewOptions" />
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
    </a-form-model-item>
      <a-form-model-item label="角色状态">
        <a-switch v-model="roleInfo.status" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <p-button
          type="primary"
          @click="onSubmit"
          name="提交"
          :loading="loading"
        />
        <p-button style="margin-left: 10px" name="取消" @click="cancelSubmit" />
      </a-form-model-item>
    </a-form-model>
   
  </a-spin>
</template>

<script>
import appApi from '@/api/app'
import roleApi from "@/api/system/role";
let _this;
export default {
  name: "SystemRoleInfo",
  props: {
    info:Object
  },
  data() {
    return {
      loading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      dataPurviewOptions:[
        '查看本部门','查看分管部门','查看所有'
      ],

      roleInfo: {
        authorizes: [],
        status: true,
      },
      activeKey:'',
      apps:[],
      checkedList:[],
      tabs:[],
      rules: {
        name: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
  },
  mounted() {
    _this = this;
    if(this.info){
      
      _this.roleInfo={...this.info};
      _this.roleInfo.status=_this.$util.int2Boolean(this.info.status)
      if(!_this.roleInfo.authorizes){
        _this.roleInfo.authorizes=[];
      }
     
      _this.checkedList=_this.roleInfo.apps;
     
    }
    this.getApp();
  },
  methods: {
    onSubmit() {
      _this.$refs.roleForm.validate((valid) => {
        if (valid) {
          _this.loading = true;
          _this.loadingTip = "保存中...";
          var data={...this.roleInfo};
          data.status=_this.$util.boolean2Int(data.status);
          var authorizes=[];
          this.tabs.forEach(item=>{
            var dp={};
            item.dataPurview.forEach(i=>{
              if(i.dataPurview && i.dataPurview.length>0)
                dp[i.key]=i.dataPurview;
            })
            authorizes.push({
              key:item.value,
              claims:item.checkedAuthorize.map(function(i){
                var arr = i.split("-");
                return {type:arr[0],value:arr[1]};
              }),
              dataPurview:dp,
              menus:item.checkedMenus
            });
          });
          data.authorizes=authorizes;
         
          data.apps=this.checkedList;
           roleApi.addOrUpdate(data).then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    appChanged(){
      _this.tabs=_this.apps.filter(a=>_this.checkedList.filter(c=>c==a.value).length>0);
      _this.activeKey=_this.tabs.length==0?'':_this.tabs[0].value;
    },
    
    getApp() {
      _this.loading = true;
      appApi.getAll().then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.apps=[];
          _this.authorize={};
          if(res.data.length>0){
            res.data.forEach(function(item){
              var f = _this.roleInfo.authorizes.filter(i=>i.key==item.appKey);
              var a = f.length>0?f[0]:null;
              var dp=a?(a.dataPurview?a.dataPurview:{}):{};
              var auth=a?(a.claims?a.claims.map(function(i){
                            return i.type+'-'+i.value;
                          }):[]):[];
              var m=a?(a.menus?a.menus:[]):[];

              var authorizesgroup={};

              if(item.authorizes){
                item.authorizes.forEach(item=>{
                   var arr = authorizesgroup[item.type];
                   if(arr==null){
                    arr=[];
                    authorizesgroup[item.type]=arr;
                   }
                   arr.push({
                    label:item.value,
                    value:item.type+'-'+item.value
                   })
                })
              }

              _this.apps.push({
                label:item.name,
                value:item.appKey,
                menus:item.menus,
                checkedMenus:m,
                authorizes:authorizesgroup,
                checkedAuthorize:auth,
                dataPurview:item.dataPurview?item.dataPurview.map(function(d){
                  return {
                    key:d,
                    dataPurview:dp[d]?dp[d]:[],
                  }
                }):[],
              });

             
             
            })
          }
          _this.appChanged();
        }
        _this.loading = false;
      });
    },
    cancelSubmit() {
      _this.$emit("callback");
    },
  },
};
</script>
<style>
</style>